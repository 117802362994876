<template>
  <div id="mArticle" class="article_list">
    <div class="head_section">
      <h2 id="dkBody" class="tit_section">공용결재선 관리</h2>
    </div>
    <div class="box_sorting">
      <strong class="screen_out">문서 검색하기</strong>
      <ul class="list_sorting">
        <li class="item_sort">
          <em class="tit_txt">결재선명</em>
          <div class="cont_sorting">
            <div class="info_search">
              <input
                v-model="apprLineName"
                type="text"
                class="inp_comm inp_search"
                title="검색하기"
                @keyup="keypress"
              />
              <span class="ico_account ico_search" />
            </div>
          </div>
        </li>
      </ul>
      <!-- // list_sorting -->
      <div class="wrap_btn">
        <button type="button" class="btn_large btn_primary" @click="onClickSearch">조회</button>
      </div>
    </div>
    <div class="area_list">
      <div class="head_tbl">
        <strong class="screen_out">결과 목록</strong>
        <div class="wrap_btn">
          <div class="pos_left">
            <button type="button" class="btn_medium btn_tertiary" @click.prevent="editPublicAppr()">
              <span class="ico_account ico_cross_w" />결재선 등록
            </button>
          </div>
        </div>
      </div>
      <div class="tbl_comm tbl_list">
        <table>
          <caption class="ir_caption">
            결과 목록 표
          </caption>
          <colgroup>
            <col style="width: 92px" />
            <col style="width: 204px" />
            <col style="width: 404px" />
            <col />
            <col style="width: 140px" />
          </colgroup>
          <thead>
            <tr>
              <th scope="col">순번</th>
              <th scope="col">결재선 명</th>
              <th scope="col">처리부서 결재선</th>
              <th scope="col">참조자</th>
              <th scope="col">등록일</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(publicApprovalLine, index) in publicApprovalLineList" :key="index">
              <td>
                {{ (newIdx > 0 ? newIdx + index + 1 : index + 1) | toInteger }}
              </td>
              <td class="cell_center">
                <a
                  href="javascript:void(0);"
                  class="link_detail"
                  @click.prevent="editPublicAppr(publicApprovalLine.cmmnAprvlLineNo)"
                >
                  {{ publicApprovalLine.name }}
                </a>
              </td>
              <td class="cell_left">
                {{ processing[index] }}
              </td>
              <td class="cell_left">
                {{ reference[index] }}
              </td>
              <td>{{ regDate[index] }}</td>
            </tr>
            <tr v-if="publicApprovalLineList.length == 0">
              <td colspan="5">조회된 문서가 없습니다.</td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- // tbl_comm -->
      <div
        class="paging_comm"
        :class="
          !publicApprovalLineList.length || publicApprovalLineList.length === 0 ? 'paging_none' : ''
        "
      >
        <paginate
          v-model="paginateConfig.pageCurrent"
          :click-handler="setPaging"
          :container-class="paginateConfig.ulClass"
          :prev-text="paginateConfig.prevText"
          :next-text="paginateConfig.nextText"
          :page-range="paginateConfig.pageRange"
          :margin-pages="paginateConfig.marginPage"
          :page-count="paginateConfig.pageCount"
        />
      </div>
      <!-- // paging_comm -->
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import ApiService from "@/services/ApiService";
import LocalStorageManager from "@/LocalStorageManager";
import { getDateStringWithTime } from "@/utils/dateUtils";

import CommOpt from "@/_approval/components/common/CommOpt";
import CommInpSearch from "@/_approval/components/common/CommInpSearch";
import { default as CommLayerMixin } from "@/_approval/mixins/common/commLayer.js";
import { default as CommFlatpickrMixin } from "@/_approval/mixins/common/commFlatpickr.js";
import { default as CommMomentMixin } from "@/_approval/mixins/common/commMoment.js";
//import { default as CommMask } from '@/_approval/mixins/common/commMask.js';

import { default as CommPaginateMixin } from "@/_approval/mixins/common/commPaginate.js";

export default {
  name: "PublicApprovalLine",
  components: { CommOpt, CommInpSearch },
  mixins: [CommLayerMixin, CommFlatpickrMixin, CommMomentMixin, CommPaginateMixin], //CommMask
  data() {
    return {
      publicApprovalLineList: [],
      apprLineName: "",
      newIdx: 0,
    };
  },
  beforeRouteEnter(to, from, next) {
    // 상세화면으로 오는 게 아니면 폼 양식 데이터 삭제
    if (from.name !== "publicApprovalLineEdit") {
      localStorage.removeItem("adminForm");
    }
    next(true);
  },
  computed: {
    processing: function () {
      // 결재선 데이터
      return this.publicApprovalLineList.map(function (item) {
        return item.deptLine.replaceAll(",", " > ");
      });
    },
    reference: function () {
      // 참조자 데이터
      return this.publicApprovalLineList.map(function (item) {
        if (!item.refName) return "";
        return item.refName.replaceAll(",", " , ");
      });
    },
    regDate: function () {
      return this.publicApprovalLineList.map(function (item) {
        if (!item.regDate) return "";
        return getDateStringWithTime(item.regDate);
      });
    },
  },
  watch: {},
  created() {
    this.paginateConfig.pageCurrent = 1;
    this.setPaging(this.paginateConfig.pageCurrent);

    // if (localStorage.adminForm) {
    //   let param = JSON.parse(localStorage.adminForm);
    //   this.apprLineName =
    //     param.apprLineName !== null ? param.apprLineName : null;
    //   this.paginateConfig.pageCurrent = param.page;
    // }
    // this.setPaging(this.paginateConfig.pageCurrent);
  },
  methods: {
    async getData(pageNo) {
      this.pageNo = 0;

      const path = `${this.$apiPath.APRVL_PBLC_APRVL_LINE}`;
      const params = { page: pageNo, size: 10, name: this.apprLineName };

      const result = await ApiService.shared.get(path, { params });
      if (!result.data) {
        this.publicApprovalLineList = [];
        return;
      }

      if (pageNo > 1) {
        this.newIdx = 10 * (pageNo - 1);
      } else {
        this.newIdx = 0;
      }

      this.publicApprovalLineList = result.data;
      this.paginateConfig.pageCount = Number(result.total);
    },
    onClickSearch() {
      this.getData(this.paginateConfig.pageCurrent);
    },
    // displaySearchResult(dataList) {
    //   /* 임직원 선택 시 노출 방식 */
    //   dataList.forEach((value) => {
    //     value.display = value.empName + '(' + value.accountId + ')';
    //   });
    // },
    // selectPublicApprovalLineList(pageNo) {
    //   /* 공용결재선 리스트 조회 */
    //   axios
    //     .post('/api/admin/selectPublicApprovalLineList', {
    //       pageNo: pageNo,
    //       apprLineName: this.apprLineName,
    //       countPerPage: 10,
    //     })
    //     .then((res) => {
    //       this.paginateConfig.pageCount = res.data.totalPage;
    //       this.publicApprovalLineList = res.data.publicApprovalLineList;
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // },
    setPaging(page) {
      this.paginateConfig.pageCurrent = page; // 선택한 페이지 번호 값 세팅 (필수)
      this.getData(this.paginateConfig.pageCurrent);
    },
    keypress(key) {
      if (key.which === 13) {
        this.getData(this.paginateConfig.pageCurrent);
      }
    },
    editPublicAppr(id) {
      // 공용결재선 상세페이지로 이동

      let adminForm = {};
      adminForm.apprLineName = this.apprLineName;
      adminForm.page = this.paginateConfig.pageCurrent;
      localStorage.adminForm = JSON.stringify(adminForm);
      this.$router.push({
        name: this.$paymentRouterPath.PAYMENT_SYSTEM_PUBLIC_LINE_WRITE,
        query: { pubApprLineId: id },
      });
    },
  },
};
</script>
